<template>
    <div>
        <div class="container">
            <div class="row">
                <div class="col-lg-12 text-left text-white print-black">
                    <h1 class="mt-5" id="heading">Hey, I'm Noah</h1>
                    <h2 class="lead" id="subheading">Nice to meet you</h2>
                    <p class="mt-4">Software Engineer
                    </p>
                </div>
            </div>
        </div>
        <Experience/>

        <Education/>
        <Skills/>
        <Contact/>
    </div>
</template>
<script>
    import Experience from "./Experience";
    import Education from "./Education";
    import Skills from "./Skills";
    import Contact from "./Contact";

    export default {
        name: "Home",
        components: {Contact, Skills, Education, Experience},
        methods: {
            loadHQ(event) {
                this.image = event.target.src;
                this.hqLoaded = true;
                console.log("loadHQ called!", this.image, this.hqLoaded);

            }
        },
        data() {
            return {
                image: "",
                hqLoaded: false,
                imageHQ: "",
                imageLQ: "",
                title: "Hi, I'm Noah",
                subtitle: "And this is (still) a placeholder"
            };
        },

        created(){
            import('../assets/placeholder/lq-header.jpg').then(img=>{
                console.log(img);
                this.imageLQ = img.default;
                this.image = img.default;
            })
        },
        mounted() {
            import('../assets/placeholder/hq-header.jpg').then(img=>{
                this.imageHQ = img.default;
            });
        }
    };
</script>
<style lang="scss">
    body {
        background: rgb(1,57,255);
        background: linear-gradient(0deg, rgba(1,57,255,1) 0%, rgba(121,151,255,1) 100%);
        background-repeat: no-repeat;
        background-attachment: fixed;
    }

    #heading {
        font-weight: 700;
        font-size: 3em;
    }

    #subheading {
        font-size: 2em;
    }
    svg {
        bottom: 0;
        left: 0;
        height: 5rem;
        width: 100%;

    }

    .boxie-last {
        padding:0;
    }

    .boxie-last > *:not(svg) {
        background-color:white;
        color: #687281;
        margin-bottom: -8px;
    }

    .boxie > *:not(svg) {
        background-color:white;
        color: #687281;
        margin-bottom: -8px;
    }

    .boxie > svg:last-of-type {
        transform: translateY(-8px) rotate(180deg);
    }

    .boxie-dark > *:not(svg) {
        background-color: #EFF3F9;
    }

    .boxie-dark svg {
        color: #EFF3F9;
    }

    .text-dark-50 {

    }

    h5 {
        font-weight:500;
        color: #323f52;
    }

    @media print {
        body {
            background: unset;
        }
        .print-black {
            color: #323f52 !important;
        }

        /*.print-black > #heading {*/
        /*    color: #687281 !important;*/
        /*}*/
        .break-print {
            page-break-after: always;
        }
        a {
            color:#687281;
        }
    }


</style>
