<template>
    <div class="container-fluid text-white boxie">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none" fill="currentColor">
            <polygon points="0,100 100,0 100,100"></polygon>
        </svg>
        <div class="w-100">
            <div class="container">
                <h2 class="text-dark text-header pt-5">EXPERIENCE</h2>
                <hr>
                <div class="experience row">
                    <div class="col-md-9">
                        <h5>Junior Developer<br><span class="text-subheader">Adwise</span></h5>
                        <p class="pb-5">As Developer for the support and services team at Adwise I have worked on a lot
                            of web based projects, but also a number of interesting challenges</p>
                    </div>
                    <div class="col-md-3 text-right">
                        <p class="text-muted">September 2018 - Present</p>
                    </div>
                </div>
                <div class="experience row">
                    <div class="col-md-9">
                        <h5>Participant<br><span class="text-subheader">Beyond Banking Hackathon</span></h5>
                        <p class="pb-5">As a team of first year students we managed to place 14th out of 40
                            participating teams, with a fully custom digital receipt system</p>
                    </div>
                    <div class="col-md-3 text-right">
                        <p class="text-muted">2018</p>
                    </div>
                </div>

            </div>

        </div>

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none" fill="currentColor">
            <polygon points="0,100 100,0 100,100"></polygon>
        </svg>

    </div>
</template>
<script>
    export default {
        name: 'Experience'
    }
</script>
<style lang="scss">
    body {
        background: rgb(1, 57, 255);
        background: linear-gradient(0deg, rgba(1, 57, 255, 1) 0%, rgba(121, 151, 255, 1) 100%);
        background-repeat: no-repeat;
        background-attachment: fixed;
    }

    svg {
        bottom: 0;
        left: 0;
        height: 5rem;
        width: 100%;

    }

    .boxie-last > *:not(svg) {
        background-color: white;
        color: #687281;
        margin-bottom: -8px;
    }

    .boxie {
        padding: 0;
        margin-right: 0;
        margin-left: 0;
    }

    .boxie > *:not(svg) {
        background-color: white;
        color: #687281;
        margin-bottom: -8px;
    }

    .boxie > svg:last-of-type {
        transform: translateY(-8px) rotate(180deg);
    }

    .text-header {
        text-transform: uppercase;
        letter-spacing: 0.1em;
    }

    .text-subheader {
        color: #687281 !important;
        font-weight: 300;
    }

    .boxie-dark > *:not(svg) {
        background-color: #EFF3F9;
    }

    .boxie-dark svg {
        color: #EFF3F9;
    }

    .text-dark {
        color: #323f52 !important;
    }

    h5 {
        font-weight: 500;
        color: #323f52;
    }

    @media print {
        body {
            background: unset;
        }
        .print-black {
            color: #323f52 !important;
        }

        /*.print-black > #heading {*/
        /*    color: #687281 !important;*/
        /*}*/
        .break-print {
            page-break-after: always;
        }
        a {
            color: #687281;
        }
    }


</style>