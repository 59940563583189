<template>
    <div class="container-fluid text-white boxie boxie-after-last">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none" fill="currentColor">
            <polygon points="0,100 100,0 100,100"></polygon>
        </svg>
        <div class="w-100">
            <div class="container pb-5 pt-5">
                <h2 class="text-dark text-header">Skills</h2>
                <hr>
                <div class="experience row">
                    <div class="col-md-12">
                    </div>
                </div>
                <div class="experience row">

                    <div class="col-md-4">
                        <p>Some programming languages I worked with</p>
                        <ul>
                            <li>JS/NodeJS</li>
                            <li>PHP</li>
                            <li>GoLang</li>
                            <li>Java</li>
                            <li>C#</li>
                            <li>C++</li>
                        </ul>
                    </div>
                    <div class="col-md-4">
                        <p>And a small selection of tools and frameworks</p>
                        <ul>
                            <li>Express</li>
                            <li>Laravel</li>
                            <li>Spring</li>
                            <li>CSS/LESS/SASS</li>
                            <li>VueJS</li>
                            <li>SQL</li>
                        </ul>
                    </div>
                    <div class="col-md-4">
                        <p>And some programming related skills</p>
                        <ul>
                            <li>UNIX server management</li>
                            <li>Windows server management</li>
                            <li>Apache2 / MySQL</li>
                            <li>Cloudflare (workers)</li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none" fill="currentColor">
            <polygon points="0,100 100,0 100,100"></polygon>
        </svg>

    </div>
</template>
<script>
    export default {
        name: 'Skills'
    }
</script>
<style lang="scss">
    body {
        background: rgb(1, 57, 255);
        background: linear-gradient(0deg, rgba(1, 57, 255, 1) 0%, rgba(121, 151, 255, 1) 100%);
        background-repeat: no-repeat;
        background-attachment: fixed;
    }

    svg {
        bottom: 0;
        left: 0;
        height: 5rem;
        width: 100%;

    }

    .boxie-last > *:not(svg) {
        background-color: white;
        color: #687281;
        margin-bottom: -8px;
    }

    .boxie {
        padding: 0;
        margin-right: 0;
        margin-left: 0;
    }

    .boxie > *:not(svg) {
        background-color: white;
        color: #687281;
        margin-bottom: -8px;
    }

    .boxie > svg:last-of-type {
        transform: translateY(-8px) rotate(180deg);
    }

    .text-header {
        text-transform: uppercase;
        letter-spacing: 0.1em;
    }

    .boxie-dark > *:not(svg) {
        background-color: #EFF3F9;
    }

    .boxie-dark svg {
        color: #EFF3F9;
    }

    .boxie-after-last {
        margin-top: -90px;
    }

    .text-dark {
        color: #323f52 !important;
    }

    h5 {
        font-weight: 500;
        color: #323f52;
    }

    @media print {
        body {
            background: unset;
        }
        .print-black {
            color: #323f52 !important;
        }

        /*.print-black > #heading {*/
        /*    color: #687281 !important;*/
        /*}*/
        .break-print {
            page-break-after: always;
        }
        a {
            color: #687281;
        }
    }


</style>