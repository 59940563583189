<template>
    <div id="app">
        <div class="container-fluid">
            <router-view/>
        </div>
    </div>
</template>
<script>
    export default {}
</script>

<style lang="scss">
    @import "~bootstrap/scss/bootstrap";

    #app {
        width: 100vw;

    }
    body {

    }
    .container-fluid {
        margin: 0;
        padding: 0;
        width: 100vw;
        overflow-x: initial;
    }

</style>
